<template>
  <section class="devices">
    <div class="row">
      <div class="col-md-6">
        <h2>Devices</h2>
        <p>Registered Devices.</p>
      </div>
      <div class="col-md-6">
        <div class="channel-stats" v-if="devices.length">
          <div v-for="(stats, channel) in channelStats" :key="channel">
            {{ channel }}: {{ stats.count }} ({{ stats.percentage }}%)
          </div>
        </div>
      </div>
    </div>
    <table class="table" v-if="devices.length">
      <thead>
        <tr>
          <th>Serial ({{ devices.length }})</th>
          <th>Type</th>
          <th>Owner</th>
          <th>Country</th>
          <th>Purchase</th>
          <th>Production</th>
          <th>Registered at</th>
        </tr>
      </thead>
      <tr v-for="device in devices" :device="device" :key="device._id">
        <td>
          <div><a :href="`https://vitesy.lightning.force.com/lightning/r/TM_Device__c/${device.crm_id}/view`" target="_blank"><strong>{{ device.serial }}</strong></a><br>{{ device.mac }}</div>
        </td>
        <td>{{ device.name }}<br>{{ device.sku }}</td>
        <td>
          <a :href="`https://vitesy.lightning.force.com/lightning/r/Contact/${device.owner.crm_id}/view`" target="_blank"><div>{{ device.owner.first_name }} {{ device.owner.last_name }}</div></a>
          <div>{{ device.owner.email }} {{ device.owner.phone }}</div>
        </td>
        <td>{{ device.owner.country }}</td>
        <td>
          <div :class="`channel channel-${device.purchase.channel}`">{{device.purchase.channel}} 
          <template v-if="device.purchase.store">/ {{device.purchase.store}}</template></div>
          <b>{{ device.purchase.order_id }}</b>
        </td>
        <td>
          <a :href="`/production/${device.production.id_number}`">{{ device.production.id_number }}</a>
          <br>
          {{ device.production.timestamp | date }}
        </td>
        <td>{{ device.createdAt | date }}</td>
      </tr>
    </table>
    <span v-else>
      <div class="loading-data" v-if="loadingData">
        <span class="loading"></span>
        <h5>Loading devices ...</h5>
      </div>
      <div class="loading-data" v-else><h6>No devices found.</h6></div>
      <div class="clearer"></div>
    </span>
  </section>
</template>

<script>
import SingleDevice from "./SingleDevice";
import DevicesService from "@/services/DevicesService";

export default {
  name: "Devices",
  data() {
    return {
      loadingData: false,
      devices: [],
      devices_list: [],
      search: "",
      nextPage: false,
      testDevices: true,
    };
  },
  computed: {
    unlinkedDevices() {
      return this.devices.filter((d) => !d.order || !d.order.id_number);
    },
    channelStats() {
      const channelCounts = this.devices.reduce((acc, device) => {
        const channel = device.purchase.channel;
        if (!acc[channel]) {
          acc[channel] = { count: 0, percentage: 0 };
        }
        acc[channel].count++;
        return acc;
      }, {});

      const totalDevices = this.devices.length;

      for (const channel in channelCounts) {
        channelCounts[channel].percentage = ((channelCounts[channel].count / totalDevices) * 100).toFixed(2);
      }

      return channelCounts;
    },
  },
  components: {
    SingleDevice,
  },
  mounted() {
    this.getDevices();
  },
  methods: {
    async getDevices() {
      this.loadingData = !this.loadingData;
      this.devices = [];
      this.devices_list = [];

      const response = await DevicesService.getDevices();
      this.devices = response.data;
      this.devices_list = response.data;
      this.loadingData = !this.loadingData;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
.channel {
  padding: .25rem;
  border-radius: .5rem;
  display: block;
  &-Ecommerce {
    background-color: lightgreen;
  }
  &-Amazon {
    background-color: orange;
  }
}
.loading-data {
  position: absolute;
  padding: 2rem;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  display: block;
  h5 {
    margin-top: 2.5rem;
  }
}
.channel-stats {
  margin-bottom: 1rem;
  div {
    margin-bottom: 0.5rem;
  }
}
</style>