<template lang="pug">
div.container
  .row 
    .col-12.col-md-6
      img.mb-4(src="/vitesy_logo.png" width="200")
    .col-12.col-md-6.text-right
      label(for="account_manager") Account Manager
        select.form-control(id="account_manager" name="account_manager" v-model="formData.account_manager" required)
          option(disabled selected value="") Select an Account Manager
          option(v-for="am in account_managers" :key="am.name" :value="am.name") {{ am.name }} | {{  am.description }}
  form.form(@submit.prevent="addLead()")
    .row.mt-3
      .col-12.col-md-6
        label(for="event") Campaign*
          select.form-control(id="campaign" name="campaign" v-model="selectedCampaign" required @change="campaignSelected")
            option(disabled selected :value="null") Select a campaign
            option(v-for="c in campaigns" :key="c.Id" :value="c") {{ c.Name }}
        br
        label(for="acquisition_channel") Acquisition Channel
        input.form-control(type="text" id="acquisition_channel" name="acquisition_channel" v-model="formData.acquisition_channel")
      .col-12.col-md-6
        div(v-if="selectedCampaign") 
          strong {{ selectedCampaign.Name }}
          p.mt-2
            span {{ selectedCampaign.Description }} 
            i ({{ selectedCampaign.Type }})
          p 
            span {{ selectedCampaign.Status }} | 
            span {{ selectedCampaign.StartDate | date }} - {{ selectedCampaign.EndDate | date }} 
      .col-12
        .mt-2.d-inline-flex.align-items-center
          input(type="checkbox" id="newsletter" name="newsletter" v-model="newsletterSubscribe")
          label.ml-2.mb-0(for="newsletter") Iscrivi a Newsletter B2B
    .row.mt-3
      .col-12.col-md-6
        label(for="company") Company*
        input.form-control(type="text" id="company" name="company" v-model="formData.company" required)
      .col-12.col-md-6
        label(for="description") Description
        textarea.form-control(id="description" name="description" v-model="formData.description")
        //- label(for="vat_number") VAT Number
        //- input.form-control(type="text" id="vat_number" name="vat_number" v-model="formData.vat_number")
    .row.mt-3
      .col-12.col-md-6
        label(for="email") Email*
        input.form-control(type="email" id="email" name="email" v-model="formData.email" required)
      .col-12.col-md-3
        label(for="phone") Phone
        input.form-control(type="phone" id="phone" name="phone" v-model="formData.phone")
      .col-12.col-md-3
        label(for="mobilephone") Mobile Phone
        input.form-control(type="mobilephone" id="mobilephone" name="mobilephone" v-model="formData.mobilephone")
    .row.mt-3
      .col-12.col-md-4
        label(for="first_name") First Name*
        input.form-control(type="text" id="first_name" name="first_name" v-model="formData.first_name" required)
      .col-12.col-md-4
        label(for="last_name") Last Name*
        input.form-control(type="text" id="last_name" name="last_name" v-model="formData.last_name" required)
      .col-12.col-md-4
        label(for="title") Title
        input.form-control(type="text" id="title" name="title" v-model="formData.title")
    .row.mt-3
      .col-12.col-md-6
        label(for="country") Country*
        select.form-control(id="country" name="country" v-model="formData.country" required @change="setLanguage()")
          option(disabled selected value="") Select a country
          option(v-for="c in countries" :key="c.code" :value="c.code") {{ c.name }}
      .col-12.col-md-6
        label(for="language") Language*
        select.form-control(id="language" name="language" v-model="formData.language" required)
          option(disabled selected value="") Select a language
          option(v-for="l in languages" :key="l.code" :value="l.code") {{ l.name }}
    .row.mt-3
      .col-12.col-md-6
        label(for="website") Website
        input.form-control(type="text" id="website" name="website" v-model="formData.website")
      .col-12.col-md-6
        label(for="notes") Notes
        textarea.form-control(id="notes" name="notes" v-model="formData.notes")
    .row.mt-3
      .col-12 
        button.btn.btn-primary.btn-lg(type="submit" :disabled="isLoading" :class="{'loading': isLoading}") Add Lead
</template>
<script>
import data from "@/assets/data.json";
import CrmService from "@/services/CrmService";

export default {
  name: "NewLead",
  data() {
    return {
      isLoading: false,
      countries: [],
      languages: data.languages,
      events: data.events,
      campaigns: [],
      selectedCampaign: null,
      newsletterSubscribe: true,
      account_managers: [
        { name: 'Federico Faleschini', description: 'Extra UE + FR, BNLX + UK + Eastern Europe'},
        { name: 'Carole Scudeler', description: 'Sales Manager DACH + Nordics/Baltics + Iberia + Greece'},
        { name: 'Mauro Mercandel', description: 'Italia'}
      ],
      formData: {
          first_name: "",
          last_name: "",
          title: "",
          company: "",
          country: "IT",
          email: "",
          phone: "",
          mobilephone: "",
          website: "",
          language: "IT",
          account_type: 'Business',
          acquisition_year: new Date().getFullYear(),
          acquisition_channel: null,
          channel_group: 'B2B',
          account_manager: '',
          notes: '',
          description: ""
      }
    }
  },
  async beforeMount() {
    const response = await CrmService.getCampaigns()
    this.campaigns = response.data
    this.listCountries()

    this.formData.account_manager = this.account_managers.findIndex(a => a.name === this.$router.user.name) >= 0 ? this.$router.user.name : this.account_managers[0].name
  },
  methods: {
    async listCountries() {
      const countries = data.countries
      countries.map((c) => {
        this.countries.push({ name: c.name, code: c.alpha2Code, vat: c.vat });
      })
    },
    campaignSelected() {
      this.formData.acquisition_channel = this.selectedCampaign.Type + '_' + this.selectedCampaign.Name
    },
    setLanguage(){
      switch(this.formData.country) {
        case 'IT':
          this.formData.language = "IT"
          break
        case 'DE':
          this.formData.language = "DE"
          break
        case 'FR':
          this.formData.language = "FR"
          break
        case 'ES':
          this.formData.language = "ES"
          break
        default :
          this.formData.language = "EN"
      }
    },
    async addLead() {
      const NEWSLETTER_B2B_CAMPAIGN_ID = '701SY00000FMfTjYAL';
      const leadData = {
        ...this.formData,
        campaignId: this.selectedCampaign.Id,
        campaign2Id: this.newsletterSubscribe ? NEWSLETTER_B2B_CAMPAIGN_ID : false
        // lead_source: this.formData.event.type,
      }
      const response = await CrmService.addLead(leadData)
      if (response && response.data && response.data.contactId) {
        this.formData.first_name = ""
        this.formData.last_name = ""
        this.formData.company = ""
        this.formData.title = ""
        this.formData.description = ""
        this.formData.country = "IT"
        this.formData.email = ""
        this.formData.phone = ""
        this.formData.mobilephone = ""
        this.formData.website = ""
        this.formData.language = "IT"
        this.formData.notes = ""
        alert('Lead aggiunta con successo!')
      } else {
        console.error(response)
        alert("Ops, Si è verificato un errore!")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";
label {
  font-weight: 700;
}
</style>
