<template>
  <tr
    @dblclick.prevent="editOrder"
    :id="[order.id_number]"
    :class="[
      `status--${order.status}`,
      order.channel,
      {
        'table-info': !isDisabled,
        replacement: order.id_number.includes('_R'),
        'table-warning': order.status == 'waiting',
      },
    ]"
  >
    <td>
      <div class="btn-group-vertical actions">
        <router-link
          class="btn btn-outline-primary btn-sm"
          :to="`/orders/${order.id_number}`"
        >
          <i class="fa fa-info-circle"></i> Details
        </router-link>
        <template v-if="$router.user.roleScore > 1">
          <button
            class="btn btn-primary btn-sm d-lg-none"
            @click.prevent="editOrder"
          >
            <i class="fa fa-edit"></i> Edit
          </button>
          <button
            class="btn btn-primary btn-sm"
            @click.prevent="updateOrder(order.id_number)"
            :disabled="isDisabled"
            :class="{ loading: isLoading }"
          >
            <i class="fa fa-save"></i> Update
          </button>
          <router-link
            v-if="!isDisabled"
            :to="{ name: 'NewOrder', params: { editOrderId: order.id_number } }"
            class="btn btn-info btn-sm"
            ><i class="fa fa-edit"></i> Edit</router-link
          >
          <router-link
            v-if="!isDisabled"
            :to="{ name: 'NewOrder', params: { primaryOrder: order } }"
            class="btn btn-success btn-sm"
            ><i class="fa fa-retweet"></i> Clone</router-link
          >
          <button
            v-if="!isDisabled"
            class="btn btn-danger btn-sm"
            @click.prevent="deleteOrder(order.id_number)"
            :disabled="isDisabled"
            :class="{ loading: isDeleting }"
          >
            <i class="fa fa-trash-alt"></i> Delete
          </button>
          <button
            v-if="!order.sap_id && $router.user.roleScore > 1"
            class="btn btn-primary btn-sm sap"
            @click.prevent="importOrder(order.id_number)"
            :class="{ loading: isLoading }"
          >
            <i class="fa fa-cloud-upload-alt"></i> Send to SAP
          </button>
        </template>
      </div>
      <div class="dropdown-info position-relative mt-2">
        <button class="btn btn-warning btn-sm dropdown-toggle w-100" v-if="order.internal_notes || order.notes">
          <i class="fas fa-sticky-note mr-2"></i> Notes
        </button>
        <div class="dropdown-menu">
          <div class="px-4" v-if="order.createdBy"><strong>Created by: </strong>{{ order.createdBy }}</div>
          <template v-if=order.internal_notes>
            <h6 class="dropdown-header">Internal Notes</h6>
            <div class="px-4">{{ order.internal_notes }}</div>
          </template>
          <template v-if=order.notes>
            <h6 class="dropdown-header">Notes</h6>
            <div class="px-4">{{ order.notes }}</div>
          </template>
        </div>
      </div>
    </td>
    <td>
      <strong>{{ order.id_number }}</strong></br>
      <template v-if="order.original_order_id_number">
        <br />
        <kbd>OO_ID: {{ order.original_order_id_number }}</kbd>
      </template>
      <br />
      <kbd class="kbd kbd--sf" v-if="order.crm && order.crm.orderId">CRM_ID: {{ order.crm.orderId }}</kbd>
    </td>
    <td>
      <div v-for="(doc, key) in order.sap_documents" :key="key">
        <div class="inline align-items-center" :class="doc.number && doc.number.length ? 'invoice' : ''">
          <small class="placeholder text-uppercase">{{ key === 'invoice' ? '#' : key }}</small>
          <input :class="doc.number && doc.number.length ? 'invoice' : ''" type="text" size="10" v-model="doc.number" :disabled="isDisabled"/> 
        </div>
        <!-- <kbd class="kbd" v-if="doc.sap_id || !isDisabled">DOC_SAP_ID: <input class="clean" size="5" v-model="doc.sap_id"/></kbd> -->
      </div>
      <kbd class="kbd" v-if="order.sap_id || !isDisabled">ERP_ID: <input class="clean" size="5" v-model="order.sap_id"/></kbd>
      <span v-if="!isDisabled" class="small mt-1 align-items-center d-inline-flex">
        <input
          type="checkbox"
          :id="'invoice' + order.id_number"
          v-model="order.invoice"
        />
        <label class="ml-1 mb-0" :for="'invoice' + order.id_number">Invoice/DDT</label>
      </span>
      <br>
      <kbd class="kbd" v-if="order.warehouse">ERP_WH: {{ order.warehouse }}</kbd>
    </td>
    <td>
      <input
      type="text"
      size="25"
      v-model="order.date"
      v-if="!isDisabled"
      :disabled="isDisabled"
      />
      <span v-else class="font-weight-bold">{{ order.date | formatDate }}</span>
      <template v-if="order.status !== 'completed' && order.status !== 'cancelled'">
        <div class="d-inline-flex text-muted align-items-center nowrap mt-2" title="Goods Readiness Date" v-if="order.goods_readiness !== order.date">{{ order.goods_readiness | formatDate }} <i class="fa fa-hourglass ml-1"></i></div>
        <div class="d-inline-flex text-muted align-items-center nowrap mt-2" title="Shipped At" v-if="order.shipments.length && order.shipments[0].shipped_at">{{ order.shipments[0].shipped_at | formatDate }} <i class="fa fa-truck ml-1"></i></div>
      </template>
    </td>
    <td>
      <select
        v-model="order.status"
        :disabled="isDisabled"
        class="mb-2 w-100"
        :class="order.status"
        title="Status"
      >
        <option
          v-for="status in statuses"
          :key="status"
          selected="selected"
          v-bind:value="status"
          >{{ status | status }}</option
        >
      </select>
      <select
        v-model="order.shipping_status"
        :disabled="isDisabled"
        class=""
        :class="order.shipping_status"
        title="Shipping Status"
      >
        <option
          v-for="shipping_status in shipping_statuses"
          :key="shipping_status"
          selected="selected"
          v-bind:value="shipping_status"
          >{{ shipping_status | status }}</option
        >
      </select>
    </td>
    <td>
      <router-link :to="`/orders/${order.id_number}`" class="badge badge-warning mb-2" v-if="order.sap_id !== 'IA' && serialsCount < serialsNeeded">
        {{ serialsCount }}/{{ serialsNeeded }} serials
      </router-link>
      <div v-for="product in order.products" :key="product.id">
        <span :class="['inline', product.bundle ? 'bundle' : '']">
          <input
            type="number"
            v-model="product.quantity"
            :disabled="isDisabled"
            size="3"
          />
          <input
            type="text"
            v-model="product.sku"
            :disabled="isDisabled"
            size="12"
          />
          <div class="inline">
            <button
              :class="[
                'btn btn-primary btn-square fa',
                product_totals ? 'fa-angle-up' : 'fa-angle-down',
              ]"
              @click.prevent="product_totals = !product_totals"
            ></button>
            <span v-if="product.discount_percentage > 0" class="p-1"
              >{{ product.discount_percentage }}%</span
            >
          </div>
        </span>
        <div v-if="product_totals" class="product__totals">
          <div style="display: grid; grid-template-columns: 1fr 2fr;">
            <label><strong>Price:</strong></label><span><input class="text-right" size="10" type="text" v-model="product.price" :disabled="isDisabled"/>{{ order.currency | currency }}</span>
            <label>Subtotal:</label><span><input class="text-right" size="10" type="text" v-model="product.subtotal" :disabled="isDisabled"/>{{ order.currency | currency }}</span>
            <label>Subtotal Tax:</label><span><input class="text-right" size="10" type="text" v-model="product.subtotal_tax" :disabled="isDisabled"/>{{ order.currency | currency }}</span>
            <label><strong>Total:</strong></label><span><input class="text-right" size="10" type="text" v-model="product.total" :disabled="isDisabled"/>{{ order.currency | currency }}</span>
            <label><strong>Total Tax:</strong></label><span><input class="text-right" size="10" type="text" v-model="product.total_tax" :disabled="isDisabled"/>{{ order.currency | currency }}</span>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div class="user__address clean">
        <div v-if="order.billing && !isDisabled">
          <input
            v-model="order.billing.first_name"
            :disabled="isDisabled"
            type="text"
            placeholder="First Name"
          />
          <input
            v-model="order.billing.last_name"
            :disabled="isDisabled"
            type="text"
            placeholder="Last Name"
          />
          <input
            v-model="order.billing.name"
            :disabled="isDisabled"
            type="hidden"
            placeholder="Name"
          />
          <input
            v-model="order.billing.company"
            :disabled="isDisabled"
            type="text"
            placeholder="Company"
          />
          <input
            v-model="order.billing.vat_number"
            :disabled="isDisabled"
            type="text"
            placeholder="VAT Number"
          />
          <input
            v-model="order.billing.address_1"
            :disabled="isDisabled"
            type="text"
            placeholder="Address 1"
          />
          <input
            v-model="order.billing.address_2"
            :disabled="isDisabled"
            type="text"
            placeholder="Address 2"
          />
          <input
            v-model="order.billing.city"
            :disabled="isDisabled"
            type="text"
            placeholder="City"
          />
          <input
            v-model="order.billing.state"
            :disabled="isDisabled"
            type="text"
            placeholder="State"
          />
          <input
            v-model="order.billing.postcode"
            :disabled="isDisabled"
            type="text"
            placeholder="Postcode"
          />
          <input
            v-model="order.billing.country"
            :disabled="isDisabled"
            type="text"
            placeholder="Country"
          />
          <input
            v-model="order.billing.email"
            :disabled="isDisabled"
            type="text"
            placeholder="Email"
          />
          <input
            v-model="order.billing.phone"
            :disabled="isDisabled"
            type="text"
            placeholder="Phone"
          />
          <input
            v-model="order.billing.e_invoice"
            :disabled="isDisabled"
            type="text"
            placeholder="E-Invoice Number"
          />
        </div>
        <div v-if="order.billing && isDisabled">
          <template v-if="order.billing.first_name && order.billing.last_name">
            <strong class="mr-1" v-text="order.billing.first_name"></strong>
            <strong v-text="order.billing.last_name"></strong>
          </template>
          <strong v-else v-text="order.billing.name"></strong>
          <span v-text="order.billing.company"></span>
          <span v-text="order.billing.vat_number"></span>
          <span v-text="order.billing.address_1"></span>
          <span v-text="order.billing.address_2"></span>
          <span v-text="order.billing.city"></span>
          <span v-text="order.billing.state"></span>
          <span v-text="order.billing.postcode"></span>
          <strong v-text="order.billing.country"></strong>
          <span v-text="order.billing.email"></span>
          <span v-text="order.billing.phone"></span>
          <span v-text="order.billing.e_invoice"></span>
        </div>
      </div>
    </td>
    <td>
      <div class="user__address clean">
        <div v-if="order.shipping && !isDisabled">
          <input
            v-model="order.shipping.first_name"
            :disabled="isDisabled"
            type="text"
            placeholder="First Name"
          />
          <input
            v-model="order.shipping.last_name"
            :disabled="isDisabled"
            type="text"
            placeholder="Last Name"
          />
          <input
            v-model="order.shipping.name"
            :disabled="isDisabled"
            type="hidden"
            placeholder="Name"
          />
          <input
            v-model="order.shipping.company"
            :disabled="isDisabled"
            type="text"
            placeholder="Company"
          />
          <input
            v-model="order.shipping.vat_number"
            :disabled="isDisabled"
            type="text"
            placeholder="VAT Number"
          />
          <input
            v-model="order.shipping.address_1"
            :disabled="isDisabled"
            type="text"
            placeholder="Address 1"
          />
          <input
            v-model="order.shipping.address_2"
            :disabled="isDisabled"
            type="text"
            placeholder="Address 2"
          />
          <input
            v-model="order.shipping.city"
            :disabled="isDisabled"
            type="text"
            placeholder="City"
          />
          <input
            v-model="order.shipping.state"
            :disabled="isDisabled"
            type="text"
            placeholder="State"
          />
          <input
            v-model="order.shipping.postcode"
            :disabled="isDisabled"
            type="text"
            placeholder="Postcode"
          />
          <input
            v-model="order.shipping.country"
            :disabled="isDisabled"
            type="text"
            placeholder="Country"
          />
          <input
            v-model="order.shipping.email"
            :disabled="isDisabled"
            type="text"
            placeholder="Email"
          />
          <input
            v-model="order.shipping.phone"
            :disabled="isDisabled"
            type="text"
            placeholder="Phone"
          />
        </div>
        <div v-if="order.shipping && isDisabled">
          <template v-if="order.shipping.first_name && order.shipping.last_name">
            <strong class="mr-1" v-text="order.shipping.first_name"></strong>
            <strong v-text="order.shipping.last_name"></strong>
          </template>
          <strong v-else v-text="order.shipping.name"></strong>
          <span v-text="order.shipping.company"></span>
          <span v-text="order.shipping.vat_number"></span>
          <span v-text="order.shipping.address_1"></span>
          <span v-text="order.shipping.address_2"></span>
          <span v-text="order.shipping.city"></span>
          <span v-text="order.shipping.state"></span>
          <span v-text="order.shipping.postcode"></span>
          <strong v-text="order.shipping.country"></strong>
          <span v-text="order.shipping.email"></span>
          <span v-text="order.shipping.phone"></span>
        </div>
      </div>
    </td>
    <td>
      <div class="clean text-right">
        <input
          size="10"
          type="text"
          class="text-right font-weight-bold"
          v-model="order.total"
          :disabled="isDisabled"
        />
        {{ order.currency | currency }}
        <span
          >(inc. tax
          <input
            size="6"
            type="text"
            class="text-right"
            v-model="order.total_tax"
            :disabled="isDisabled"
          />{{ order.currency | currency }})</span
        >
      </div>
    </td>
    <td>
      <div class="text-right clean">
        <input
          size="4"
          type="text"
          class="text-right"
          v-model="order.shipping_total"
          :disabled="isDisabled"
        />
          {{ order.currency | currency }}
        <span
          >(tax
          <input
            size="6"
            type="text"
            class="text-right"
            v-model="order.shipping_tax"
            :disabled="isDisabled"
          />)</span
        >
      </div>
      <span v-if="order.shipping_method" class="badge badge-pill mt-2" 
        :class="order.shipping_method && order.shipping_method.includes('xpress') ? 'badge-danger' : 'badge-secondary'">
        {{ order.shipping_method }}
      </span>
    </td>
    <td>
      <div
        class="text-right clean"
        v-if="
          order.discount_total > 0 ||
          order.discount_percentage > 0 ||
          !isDisabled
        "
      >
      <div class="d-inline-flex align-items-center">
        <input
          size="10"
          type="text"
          class="text-right"
          v-model="order.discount_total"
          :disabled="isDisabled"
        />
        <span>{{ order.currency | currency }}</span>
      </div>
        <span
          >(<input
            size="6"
            type="text"
            class="text-right"
            v-model="order.discount_percentage"
            :disabled="isDisabled"
          />%)</span
        >
        <span class="badge badge-dark badge-pill mt-2" v-if="order.discount_code && order.discount_code.length">DC: {{ order.discount_code }}</span>
      </div>
    </td>
    <!-- <td class="clean">
      <input
        size="3"
        type="text"
        v-model="order.currency"
        :disabled="isDisabled"
      />
    </td> -->
    <!-- <td :class="order.payment ? order.payment.payment_method : ''">
      <span v-if="order.payment">
        <em class="clean"
          ><input
            type="text"
            v-model="order.payment.payment_method"
            :disabled="isDisabled"
        /></em>
        <small
          v-if="['stripe', 'paypal'].includes(order.payment.payment_method)"
        >
          <button
            :class="[
              'btn btn-primary btn-square fa ml-2',
              payment_details ? 'fa-angle-up' : 'fa-angle-down',
            ]"
            @click.prevent="payment_details = !payment_details"
          ></button>
          <div v-if="payment_details" class="product__totals">
            <span v-text="'Currency: ' + order.payment.details.currency"></span>
            <span v-text="'Fee: ' + order.payment.details.fee"></span>
            <span
              v-text="
                'Receivable amount: ' + order.payment.details.receivable_amount
              "
            ></span>
            <span
              v-text="'Exchange rate: ' + order.payment.details.exchange_rate"
            ></span>
          </div>
        </small>
      </span>
    </td> -->
    <td>
      <template v-if="order.payment">
        <input
          type="checkbox"
          :id="'payment_paid' + order.id_number"
          v-model="order.payment.paid"
          :disabled="isDisabled"
          class="mr-2"
        />
        <label :for="'payment_paid' + order.id_number">{{ order.payment.paid ? 'Paid' : 'Not paid'}}</label>
        <br>
        <input
          size="3"
          type="text"
          class="clean"
          v-model="order.payment.paid_percentage"
          :disabled="isDisabled"
        />%
        <div v-if="order.payment.payment_method" class="badge badge-pill mt-2 p-2 d-inline-flex w-100 justify-content-between c-pointer"
          @click.prevent="paymentDetailsToggle = !paymentDetailsToggle"
          :class="order.payment.payment_method && order.payment.payment_method.includes('paypal') ? 'badge-primary' : order.payment.payment_method.includes('stripe') ? 'badge-info' : 'badge-warning'">
          {{ order.payment.payment_method }}
          <span v-if="hasPaymentDetails" class="fa ml-2 fa-angle-down"></span>
        </div>
        <div v-if="hasPaymentDetails && paymentDetailsToggle" class="product__totals">
          <div class="d-inline-flex">
            <div class="mx-2">
              <small class="d-block font-weight-bold">Gross</small>
              {{ order.payment.details.amount, order.payment.details.currency | price }}
            </div>
            <div class="mx-2">
              <small class="d-block font-weight-bold">Fee</small>
              {{ order.payment.details.fee, order.payment.details.currency | price }}
            </div>
            <div class="mx-2">
              <small class="d-block font-weight-bold">Net</small>
              {{ order.payment.details.receivable_amount, order.payment.details.currency | price }}
            </div>
          </div>
          <div class="d-inline-flex mt-2"> 
            <div class="mx-2" v-if="order.payment.details.exchange_rate > 0">
              <small class="d-block font-weight-bold">EXR ⇄</small>
              {{ order.payment.details.exchange_rate }}
            </div>
            <div class="mx-2">
              <small class="d-block font-weight-bold">Charge</small>
              {{ order.payment.details.charge_date | date }}
            </div>
            <div class="mx-2">
              <small class="d-block font-weight-bold">Payout</small>
              {{ order.payment.details.payout_date | date }}
            </div>
          </div>
        </div>
      </template>
    </td>
    <td class="clean segment">
      <div class="badge">
        <small class="d-block font-weight-bold">Channel Group</small><input type="text" v-model="order.channel_group" :disabled="isDisabled" />
      </div>
      <div class="badge">
        <small class="d-block font-weight-bold">Channel</small><input type="text" v-model="order.channel" :disabled="isDisabled" />
      </div>
      <div class="badge">
        <small class="d-block font-weight-bold">Typology</small><input type="text" v-model="order.typology" :disabled="isDisabled" />
      </div>
      <div class="badge">
        <small class="d-block font-weight-bold">Source</small><input type="text" v-model="order.source" :disabled="isDisabled" />
      </div>
    </td>
    <td class="clean"><textarea v-model="order.notes"></textarea></td>
    <td class="clean"><textarea v-model="order.internal_notes"></textarea></td>
    <td v-if="this.$router.user.roleScore > 2" style="min-width:300px"><code v-html="order.metadata"></code></td>
  </tr>
</template>
<script>
import SapService from "@/services/SapService";
import OrdersService from "@/services/OrdersService";
import { skusWithSerial } from "@/assets/data.json";
export default {
  name: "SingleOrder",
  props: ["order"],
  data() {
    return {
      isDisabled: true,
      isLoading: false,
      isDeleting: false,
      statuses: [
        "processing",
        "processed",
        "shipping",
        "completed",
        "waiting",
        "refunded",
        "failed",
        "cancelled",
        "standby",
        "resent",
      ],
      shipping_statuses: [
        "-",
        "ready-for-shipping",
        "in-transit",
        "delivered",
        "hand-delivered",
        "exception",
        "on-hold",
        "returned",
        "lost",
      ],
      product_totals: false,
      paymentDetailsToggle: true,
      skusWithSerial
    };
  },
  computed: {
    hasPaymentDetails(){
      return this.order.payment && this.order.payment.details && this.order.payment.details.fee
    },
    serialsCount() {
      return this.order.shipments.reduce((a,c) => a + c.serials.length,0)
    },
    serialsNeeded() {
      return this.order.products.reduce((a,c) => {
        if (skusWithSerial.includes(c.sku.substr(0,8))) {
          return a+c.quantity
        } else {
          return a
        }
      },0)
    }
  },
  filters: {
    status: function (str) {
      if (str !== "-") {
        str = str.replace(/-/g, " ");
      }
      return str;
    },
  },
  methods: {
    async importOrder(id) {
      this.isLoading = true
      const response = await SapService.importOrder(id)
      if (response && response.data && response.data.length > 6) {
        alert(response.data)
      }
      this.isLoading = false
    },
    editOrder() {
      if (this.$router.user.roleScore > 1) {
        this.isDisabled = !this.isDisabled;
      }
    },
    async updateOrder(id) {
      this.isLoading = !this.isLoading;
      this.isDisabled = !this.isDisabled;
      this.order.billing.last_name && this.order.billing.last_name.length ? this.order.billing.name = `${this.order.billing.first_name} ${this.order.billing.last_name}` : this.order.billing.last_name = this.order.billing.name
      this.order.shipping.last_name && this.order.shipping.last_name.length ? this.order.shipping.name = `${this.order.shipping.first_name} ${this.order.shipping.last_name}` : this.order.shipping.last_name = this.order.shipping.name
      this.order.billing.name = this.order.billing.name.trim()
      this.order.shipping.name = this.order.shipping.name.trim()

      await OrdersService.updateOrder(id, this.order).then(() => {
        this.isLoading = !this.isLoading;
      });
    },
    async deleteOrder(id) {
      if (confirm("Are you sure?")) {
        this.isDeleting = !this.isDeleting;
        await OrdersService.deleteOrder(id).then(() => {
          this.isDeleting = !this.isDeleting;
        });
        document.getElementById(id).style.display = "none";
      } else {
        this.isDisabled = !this.isDisabled;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
.segment > .badge {
  font-size: .8rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  text-align: left;
  grid-gap: .75rem;
}
tr {
  border-left: 0.3rem solid;
  &.status--processed {
    border-color: $clairy;
  }
  &.status--completed {
    border-color: $green;
  }
  &.status--waiting {
    border-color: $red;
  }
  &.status--shipping {
    border-color: $yellow;
  }
  
  &.replacement td {
    background-color: rgba($sand, 0.2);
  }
  &.replacement {
    box-shadow: inset 0 0.15rem 0.3rem 0 rgba(0, 0, 0, 0.1),
      inset 0 -0.15rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0.15rem 0.3rem 0 rgba(0, 0, 0, 0.1),
      inset 0 -0.15rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 0.15rem 0.3rem 0 rgba(0, 0, 0, 0.1),
      inset 0 -0.15rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: inset 0 0.15rem 0.3rem 0 rgba(0, 0, 0, 0.1),
      inset 0 -0.15rem 0.3rem 0 rgba(0, 0, 0, 0.1);
  }
  td {
    vertical-align: middle !important;
    border-left: none;
    border-right-color: rgba(0, 0, 0, 0.03);
    span {
      display: block;
      white-space: nowrap;
    }
  }
}
.ups {
  color: $ups;
  background-color: $ups_bg;
  &:hover {
    color: $ups_bg;
    background-color: $ups;
  }
}

.tracking_numbers {
  max-height: 200px;
  overflow-y: auto;
}
.clean input,
input.clean,
.clean textarea {
  width: auto;
  background: transparent;
  border: none;
}
textarea {
  min-width: 250px;
  min-height: 80px;
}
.user__address {
  &.compact {
    height: 1.2rem;
    overflow: hidden;
  }
  input {
    min-width: 200px;
  }
}
.inline {
  //margin: .2rem auto;
  display: inline-flex;
}
.clean input {
  flex: 1 1 auto;
}
.form-control {
  width: auto;
}
input[type="number"] {
  width: 4rem;
  text-align: center;
}
select,
.text-capitalize {
  text-transform: capitalize;
  &.completed,
  &.delivered,
  &.hand-delivered {
    background-color: $green;
    color: #fff;
  }
  &.shipping,
  &.in-transit {
    background-color: $ups;
    color: #fff;
  }
  &.waiting,
  &.exception {
    background-color: $red;
    color: #fff;
  }
  &.processed,
  &.ready-for-shipping {
    background-color: $clairy;
    color: #fff;
  }
  &.standby {
    background-color: $gray02;
    color: #fff;
  }
}
select {
  border-radius: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0 1rem;
  line-height: 1.5rem;
  outline: none !important;
  border: none;
}
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
  &-body {
    padding: 0;
  }
  .modal-xl {
    max-width: 98%;
  }
  button.close {
    background-color: #b83339;
    color: #fff;
    font-weight: 400;
    padding: 1.2rem 1.5rem;
    opacity: 1;
  }
}
kbd.kbd {
  background-color: $sap_color;
  display: inline-block;
  margin-top: 0.5rem;
  white-space: nowrap;
  &--sf {
    background-color: $salesforce_color;
  }
  input {
    color: #fff;
    padding: 0 0.1rem;
  }
}
input {
  padding: 0.2rem.5rem;
  border: 1px solid rgba(0, 0, 0, 0.04);
}
.invoice {
  background-color: #000;
  color: #fff;
  border-radius: 0.25rem;
}
.bundle {
  border-left: 3px solid $orange;
}
.product__totals {
  font-size: 0.7rem;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.05);
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 80%;
  }
}
body.modal-open {
  overflow: hidden;
}
</style>
